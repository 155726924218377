import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Medical App Template | Medical App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/medical-mobile-app/"
    metaDescription="Introducing MedKit, our medical app design template for mobile. This template comes with all the must-have features for your medical app design."
    description="
    h2:Introducing MedKit, our mobile medical app design template
    <br/>
    The doctor will see you now… MedKit, Uizard's medical app design template for mobile, is the perfect antidote to your design woes. Packed with all the must-have features for a medical app design, MedKit is ready to use straight out of the box, making your <a:https://uizard.io/prototyping/>app UI prototyping</a> journey as quick and as simple as possible.
    <br/>
    h3:Uizard prescribes you a chic and minimalist design
    <br/>
    MedKit's stylish and clean aesthetic is sure to impress. We've designed MedKit with a stunning minimalist approach to help give your version of the design a professional, clinical feel. To get started, simply open the template and tweak to suit your own branding or stylistic preferences..
    <br/>
    h3:Prognosis: a stunning mobile healthcare medical app design created in minutes 
    <br/>
    Uizard empowers you to create stunning <a:https://uizard.io/templates/mobile-app-templates/>mobile app designs</a>, and MedKit is no exception. Our smart features and design components allow you to easily customize our base template rapidly, meaning you could have a workable prototype completed in minutes.
    "
    pages={[
      "A charming login page with sleek visuals",
      "A clean and concise homepage screen with clear navigation and CTAs pointing toward secondary pages",
      "An appointment page, a medication overview page, and a 'find my physician' search page",
      "Detailed pages also include doctor contact information, example call functionality, and physician profile pages",
    ]}
    projectCode="aBY9559rjof5P79Qzeev"
    img1={data.image1.childImageSharp}
    img1alt="medical app design overview"
    img2={data.image2.childImageSharp}
    img2alt="medical app design dashboard screen"
    img3={data.image3.childImageSharp}
    img3alt="medical app design course doctor profile screen"
    img4={data.image4.childImageSharp}
    img4alt="medical app design patient profile screen"
    img5={data.image5.childImageSharp}
    img5alt="medical app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/medical-mobile-app/medical-app-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/medical-mobile-app/medical-app-dashboard.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/medical-mobile-app/medical-app-doctor.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/medical-mobile-app/medical-app-profile.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/medical-mobile-app/medical-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
